<template>
  <!-- customer-cards START -->
  <div class="account-customer-cards">
    <!-- customer-cards:headline START -->
    <div
      class="account-customer-cards-headline"
      v-html="$t('account.customercard.standardheadline')"
    ></div>
    <!-- customer-cards:headline END -->

    <!-- customer-cards:standard START -->
    <div class="account-customer-cards-standard">
      <div class="inner">
        <p>Max Mustermann</p>
        <p class="number">1234-4321-5678-8765</p>
      </div>
      <router-link
        class="edit"
        :to="'/account/customer-card'"
      ></router-link>
      <router-link
        class="delete"
        :to="'/account/customer-cards'"
      ></router-link>
    </div>
    <!-- customer-cards:standard END -->

    <!-- customer-cards:headline START -->
    <div
      class="account-customer-cards-headline"
      v-html="`
        ${$t('account.customercard.furtherheadline')}
        <span>${$t('account.customercard.nofurthercard')}</span>
      `"
    ></div>
    <!-- customer-cards:headline END -->

    <!-- customer-cards:add START -->
    <router-link
      class="btn-inverted"
      :to="'/account/customer-card'"
      v-html="$t('account.customercard.addnewcard')"
    ></router-link>
    <!-- customer-cards:add END -->
  </div>
  <!-- customer-cards END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'customer-cards',
  mixins: [titleMixin],
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.customercard.headline'),
    };
  },
};
</script>
